import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MiniResourceCard = makeShortcode("MiniResourceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <MiniResourceCard slots="image,heading,link" repeat="9" theme="lightest" inRow="3" className="mini-card support-tools index-minicards" mdxType="MiniResourceCard" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "100px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block",
            "transition": "opacity 0.5s 0.5s",
            "pointerEvents": "none"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/1af229463c7e8022d403fc42fc1e252f/e976a/firefly_appicon.webp 100w"],
            "sizes": "(max-width: 100px) 100vw, 100px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/1af229463c7e8022d403fc42fc1e252f/effb6/firefly_appicon.png 100w"],
            "sizes": "(max-width: 100px) 100vw, 100px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/1af229463c7e8022d403fc42fc1e252f/effb6/firefly_appicon.png",
            "alt": "Adobe Firefly Services",
            "title": "Adobe Firefly Services",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "opacity": "0",
              "transition": "opacity 0.5s",
              "color": "inherit",
              "boxShadow": "inset 0px 0px 0px 400px none",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h3 {...{
      "id": "adobe-firefly-services"
    }}>{`Adobe Firefly Services`}</h3>
    <p><a parentName="p" {...{
        "href": "https://developer.adobe.com/firefly-services/"
      }}>{`Learn More`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "100px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block",
            "transition": "opacity 0.5s 0.5s",
            "pointerEvents": "none"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/966fb4242ffb38337debb5c4c4d409ec/e976a/dc_appicon_64.webp 100w"],
            "sizes": "(max-width: 100px) 100vw, 100px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/966fb4242ffb38337debb5c4c4d409ec/effb6/dc_appicon_64.png 100w"],
            "sizes": "(max-width: 100px) 100vw, 100px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/966fb4242ffb38337debb5c4c4d409ec/effb6/dc_appicon_64.png",
            "alt": "Adobe Document Cloud",
            "title": "Adobe Document Cloud",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "opacity": "0",
              "transition": "opacity 0.5s",
              "color": "inherit",
              "boxShadow": "inset 0px 0px 0px 400px none",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h3 {...{
      "id": "adobe-document-cloud"
    }}>{`Adobe Document Cloud`}</h3>
    <p><a parentName="p" {...{
        "href": "https://developer.adobe.com/document-services/homepage"
      }}>{`Learn More`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "100px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block",
            "transition": "opacity 0.5s 0.5s",
            "pointerEvents": "none"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/b313f64b5eb9450d71c2bf901d4a8358/e976a/experience_platform_appicon_RGB_noshadow_64.webp 100w"],
            "sizes": "(max-width: 100px) 100vw, 100px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/b313f64b5eb9450d71c2bf901d4a8358/effb6/experience_platform_appicon_RGB_noshadow_64.png 100w"],
            "sizes": "(max-width: 100px) 100vw, 100px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/b313f64b5eb9450d71c2bf901d4a8358/effb6/experience_platform_appicon_RGB_noshadow_64.png",
            "alt": "Adobe Experience Platform",
            "title": "Adobe Experience Platform",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "opacity": "0",
              "transition": "opacity 0.5s",
              "color": "inherit",
              "boxShadow": "inset 0px 0px 0px 400px none",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h3 {...{
      "id": "adobe-experience-platform"
    }}>{`Adobe Experience Platform`}</h3>
    <p><a parentName="p" {...{
        "href": "https://developer.adobe.com/experience-platform-apis/"
      }}>{`Discover`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "100px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block",
            "transition": "opacity 0.5s 0.5s",
            "pointerEvents": "none"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/725104ed31dc4790bb6c1a99bd1e14bf/e976a/express.webp 100w"],
            "sizes": "(max-width: 100px) 100vw, 100px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/725104ed31dc4790bb6c1a99bd1e14bf/effb6/express.png 100w"],
            "sizes": "(max-width: 100px) 100vw, 100px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/725104ed31dc4790bb6c1a99bd1e14bf/effb6/express.png",
            "alt": "Adobe Express",
            "title": "Adobe Express",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "opacity": "0",
              "transition": "opacity 0.5s",
              "color": "inherit",
              "boxShadow": "inset 0px 0px 0px 400px none",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h3 {...{
      "id": "adobe-express"
    }}>{`Adobe Express`}</h3>
    <p><a parentName="p" {...{
        "href": "https://developer.adobe.com/express/"
      }}>{`Learn More`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "100px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block",
            "transition": "opacity 0.5s 0.5s",
            "pointerEvents": "none"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/b313f64b5eb9450d71c2bf901d4a8358/e976a/experience_platform_appicon_RGB_noshadow_64.webp 100w"],
            "sizes": "(max-width: 100px) 100vw, 100px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/b313f64b5eb9450d71c2bf901d4a8358/effb6/experience_platform_appicon_RGB_noshadow_64.png 100w"],
            "sizes": "(max-width: 100px) 100vw, 100px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/b313f64b5eb9450d71c2bf901d4a8358/effb6/experience_platform_appicon_RGB_noshadow_64.png",
            "alt": "Adobe Developer App Builder",
            "title": "Adobe Developer App Builder",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "opacity": "0",
              "transition": "opacity 0.5s",
              "color": "inherit",
              "boxShadow": "inset 0px 0px 0px 400px none",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h3 {...{
      "id": "adobe-developer-app-builder"
    }}>{`Adobe Developer App Builder`}</h3>
    <p><a parentName="p" {...{
        "href": "https://developer.adobe.com/app-builder/"
      }}>{`Learn More`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "100px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block",
            "transition": "opacity 0.5s 0.5s",
            "pointerEvents": "none"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/b313f64b5eb9450d71c2bf901d4a8358/e976a/experience_platform_appicon_RGB_noshadow_64.webp 100w"],
            "sizes": "(max-width: 100px) 100vw, 100px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/b313f64b5eb9450d71c2bf901d4a8358/effb6/experience_platform_appicon_RGB_noshadow_64.png 100w"],
            "sizes": "(max-width: 100px) 100vw, 100px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/b313f64b5eb9450d71c2bf901d4a8358/effb6/experience_platform_appicon_RGB_noshadow_64.png",
            "alt": "Adobe Commerce",
            "title": "Adobe Commerce",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "opacity": "0",
              "transition": "opacity 0.5s",
              "color": "inherit",
              "boxShadow": "inset 0px 0px 0px 400px none",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h3 {...{
      "id": "adobe-commerce"
    }}>{`Adobe Commerce`}</h3>
    <p><a parentName="p" {...{
        "href": "https://developer.adobe.com/commerce/"
      }}>{`Learn More`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "100px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block",
            "transition": "opacity 0.5s 0.5s",
            "pointerEvents": "none"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/3b29e203b1489b5a957e858b059948f7/e976a/creativecloud.webp 100w"],
            "sizes": "(max-width: 100px) 100vw, 100px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/3b29e203b1489b5a957e858b059948f7/effb6/creativecloud.png 100w"],
            "sizes": "(max-width: 100px) 100vw, 100px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/3b29e203b1489b5a957e858b059948f7/effb6/creativecloud.png",
            "alt": "Adobe Creative Cloud",
            "title": "Adobe Creative Cloud",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "opacity": "0",
              "transition": "opacity 0.5s",
              "color": "inherit",
              "boxShadow": "inset 0px 0px 0px 400px none",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h3 {...{
      "id": "adobe-creative-cloud"
    }}>{`Adobe Creative Cloud`}</h3>
    <p><a parentName="p" {...{
        "href": "https://developer.adobe.com/creative-cloud/"
      }}>{`Learn More`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "100px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block",
            "transition": "opacity 0.5s 0.5s",
            "pointerEvents": "none"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/966fb4242ffb38337debb5c4c4d409ec/e976a/dc_appicon_64.webp 100w"],
            "sizes": "(max-width: 100px) 100vw, 100px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/966fb4242ffb38337debb5c4c4d409ec/effb6/dc_appicon_64.png 100w"],
            "sizes": "(max-width: 100px) 100vw, 100px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/966fb4242ffb38337debb5c4c4d409ec/effb6/dc_appicon_64.png",
            "alt": "Adobe PDF Embed API",
            "title": "Adobe PDF Embed API",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "opacity": "0",
              "transition": "opacity 0.5s",
              "color": "inherit",
              "boxShadow": "inset 0px 0px 0px 400px none",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h3 {...{
      "id": "adobe-pdf-embed-api"
    }}>{`Adobe PDF Embed API`}</h3>
    <p><a parentName="p" {...{
        "href": "https://developer.adobe.com/document-services/apis/pdf-embed/"
      }}>{`Learn More`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "100px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block",
            "transition": "opacity 0.5s 0.5s",
            "pointerEvents": "none"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/b313f64b5eb9450d71c2bf901d4a8358/e976a/experience_platform_appicon_RGB_noshadow_64.webp 100w"],
            "sizes": "(max-width: 100px) 100vw, 100px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/b313f64b5eb9450d71c2bf901d4a8358/effb6/experience_platform_appicon_RGB_noshadow_64.png 100w"],
            "sizes": "(max-width: 100px) 100vw, 100px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/b313f64b5eb9450d71c2bf901d4a8358/effb6/experience_platform_appicon_RGB_noshadow_64.png",
            "alt": "Adobe Analytics API",
            "title": "Adobe Analytics API",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "opacity": "0",
              "transition": "opacity 0.5s",
              "color": "inherit",
              "boxShadow": "inset 0px 0px 0px 400px none",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h3 {...{
      "id": "adobe-analytics-api"
    }}>{`Adobe Analytics API`}</h3>
    <p><a parentName="p" {...{
        "href": "https://developer.adobe.com/analytics-apis/docs/2.0/"
      }}>{`Learn More`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      