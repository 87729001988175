import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TitleBlock = makeShortcode("TitleBlock");
const ResourceCard = makeShortcode("ResourceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <TitleBlock slots="heading" theme="lightest" mdxType="TitleBlock" />
    <h2 {...{
      "id": "latest-developer-news"
    }}>{`Latest Developer News`}</h2>
    <ResourceCard slots="link, image, heading, text" width="33%" theme='lightest' className="useCaseCard" mdxType="ResourceCard" />
    <p><a parentName="p" {...{
        "href": "https://developer.adobe.com/compliance/"
      }}></a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "40%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block",
            "transition": "opacity 0.5s 0.5s",
            "pointerEvents": "none"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/eee52e2e9745c83476c52392844ae6aa/a5e6d/compliance.webp 200w"],
            "sizes": "(max-width: 200px) 100vw, 200px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/eee52e2e9745c83476c52392844ae6aa/56d15/compliance.png 200w"],
            "sizes": "(max-width: 200px) 100vw, 200px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/eee52e2e9745c83476c52392844ae6aa/56d15/compliance.png",
            "alt": "App Builder",
            "title": "App Builder",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "opacity": "0",
              "transition": "opacity 0.5s",
              "color": "inherit",
              "boxShadow": "inset 0px 0px 0px 400px none",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h3 {...{
      "id": "adobe-dsa-compliance"
    }}>{`Adobe DSA Compliance`}</h3>
    <p>{`Get insights on Adobe's compliance with the European Union's (EU) Digital Services Act (DSA)`}</p>
    <ResourceCard slots="link, image, heading, text" width="33%" theme='lightest' className="useCaseCard" mdxType="ResourceCard" />
    <p><a parentName="p" {...{
        "href": "https://developer.adobe.com/express/add-ons/"
      }}></a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1280px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block",
            "transition": "opacity 0.5s 0.5s",
            "pointerEvents": "none"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/626d53429622009c145b3491706f12d5/5530d/Express_AddOns.webp 320w", "/static/626d53429622009c145b3491706f12d5/0c8fb/Express_AddOns.webp 640w", "/static/626d53429622009c145b3491706f12d5/94b1e/Express_AddOns.webp 1280w", "/static/626d53429622009c145b3491706f12d5/0b34d/Express_AddOns.webp 1920w", "/static/626d53429622009c145b3491706f12d5/d5269/Express_AddOns.webp 2560w", "/static/626d53429622009c145b3491706f12d5/b6a6b/Express_AddOns.webp 2707w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/626d53429622009c145b3491706f12d5/8980b/Express_AddOns.jpg 320w", "/static/626d53429622009c145b3491706f12d5/56d4e/Express_AddOns.jpg 640w", "/static/626d53429622009c145b3491706f12d5/62aaf/Express_AddOns.jpg 1280w", "/static/626d53429622009c145b3491706f12d5/aaf92/Express_AddOns.jpg 1920w", "/static/626d53429622009c145b3491706f12d5/36eba/Express_AddOns.jpg 2560w", "/static/626d53429622009c145b3491706f12d5/42f76/Express_AddOns.jpg 2707w"],
            "sizes": "(max-width: 1280px) 100vw, 1280px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/626d53429622009c145b3491706f12d5/62aaf/Express_AddOns.jpg",
            "alt": "Express Add-Ons",
            "title": "Express Add-Ons",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "opacity": "0",
              "transition": "opacity 0.5s",
              "color": "inherit",
              "boxShadow": "inset 0px 0px 0px 400px none",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h3 {...{
      "id": "make-building-add-ons-your-superpower"
    }}>{`Make building add-ons your superpower`}</h3>
    <p>{`Build add-ons to extend the functionality of Adobe Express and unlock new creative workflows for users.`}</p>
    <ResourceCard slots="link, image, heading, text" width="33%" theme='lightest' className="useCaseCard" mdxType="ResourceCard" />
    <p><a parentName="p" {...{
        "href": "https://max.adobe.com/"
      }}></a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "40%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block",
            "transition": "opacity 0.5s 0.5s",
            "pointerEvents": "none"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/de27690c1e1c34c7b79fb09aead70100/5530d/max.webp 320w", "/static/de27690c1e1c34c7b79fb09aead70100/0c8fb/max.webp 640w", "/static/de27690c1e1c34c7b79fb09aead70100/cbd37/max.webp 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/de27690c1e1c34c7b79fb09aead70100/8980b/max.jpg 320w", "/static/de27690c1e1c34c7b79fb09aead70100/56d4e/max.jpg 640w", "/static/de27690c1e1c34c7b79fb09aead70100/03ffe/max.jpg 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/de27690c1e1c34c7b79fb09aead70100/03ffe/max.jpg",
            "alt": "Adobe MAX",
            "title": "Adobe MAX",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "opacity": "0",
              "transition": "opacity 0.5s",
              "color": "inherit",
              "boxShadow": "inset 0px 0px 0px 400px none",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h3 {...{
      "id": "adobe-max-see-what-you-missed"
    }}>{`Adobe MAX. See what you missed.`}</h3>
    <p>{`Watch MAX on demand for the latest industry innovations, product tips and techniques, and new ways to ignite your imagination.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      